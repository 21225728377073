/* Booking module styles */

.ant-upload-list-item-done{
    border: none !important;
    padding: 17px !important;
}

.ant-upload-list-item-container{
  border: 1px solid #d9d9d9 !important;
  border-radius: 10px !important;
  word-break: break-word !important;
  width: 200px !important;
  height: auto !important;
}
  