body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hover:hover{
  transform: scale(1.03)
}
.dateRangePicker{
  border: 2px;
  border-radius: 25px;
background-color: #007bff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.collapse-item.active{
  background-color: #007bff;
}

.navbar-expand .custom-toggler{
  display: inline-block !important;
  border:none !important;
}

.custom-select {
  position: relative;
  display: inline-block;
}

.custom-arrow {
  position: absolute;
  top: 51px;
  right: 20px; /* Adjust the distance from the right as needed */
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #8a8686; /* Adjust the color as needed */
  pointer-events: none; /* Ensure the arrow doesn't interfere with the select */
}

/* Hide the default arrow in some browsers */
select::-ms-expand {
  display: none;
}

 .hideArrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 20px; /* Adjust the space for the custom arrow */
}

.active-whitelabel {
  background: #4caf50 !important; /* Whitelabel active link background color */
  font-weight: bold !important;
  font-size: large !important;
}

.active-default {
  background: #4e73df !important; /* Default active link background color */
  font-weight: bold !important;
  font-size: large !important;
  
}

.active-whitelabel-pagination a{
  background: rgb(0, 153, 0)  !important ;
  border-color:rgb(0, 153, 0) !important;
  color: white;
}

.active-default-pagination a{
  background: #4e73df !important;
  border-color: #4e73df ;
  color: white;
}
.pt-md-2{
  padding-top: 0px !important;
}

@media screen and (max-width: 768px) {
  .pt-md-2{
    padding-top: 8px !important;
  }
}
